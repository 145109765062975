/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { BPMN, CONNECTOR_TEMPLATE, DMN, FORM, IMPORT_DIALOG_SOURCE } from 'utils/constants';
import { Warning } from 'icons';
import { getFileTypeByExtensionOrContent } from 'utils/file-io/read-files';
import BaseImportService from 'components/ImportModal/BaseImportService';
import { importDmnService, importFormService, importProcessTemplateService } from 'components/ImportModal/index';
import { processApplicationService } from 'services';
import { getBpmnAttributes } from 'utils/web-modeler-diagram-parser';

class ImportDelegatorService extends BaseImportService {
  async prepareOutput(templates, sourceUrl) {
    const importService = this.#guessImportServiceFromSourceUrl(sourceUrl, templates);
    if (importService) {
      return importService.prepareOutput(templates);
    } else {
      return { error: 'Unsupported file type' };
    }
  }

  async fetchResources(listOfResources) {
    const supportedResourceUrls = listOfResources.filter((resourceUrl) =>
      this.#guessImportServiceFromSourceUrl(resourceUrl)
    );

    return super.fetchResources(supportedResourceUrls);
  }

  async import({ selectedProject, resourcesMetadata, folderId, isFolderProcessApplication = false }) {
    let folderIdToUse = folderId;
    let importedMainProcess;
    let resourcesMetadataToUse = resourcesMetadata;

    const { processApplicationId, mainProcess } = await this.#createProcessApplication({
      selectedProject,
      resourcesMetadata,
      folderId,
      isFolderProcessApplication
    });

    if (processApplicationId) {
      folderIdToUse = processApplicationId;
      importedMainProcess = mainProcess;
      resourcesMetadataToUse = resourcesMetadata.filter((resource) => resource.source !== mainProcess.source);
    }

    const remainingImportedFiles = await this.importNoDuplicateCheck({
      selectedProject,
      resourcesMetadata: resourcesMetadataToUse,
      folderId: folderIdToUse,
      source: IMPORT_DIALOG_SOURCE
    });

    if (importedMainProcess && remainingImportedFiles) {
      return [importedMainProcess, ...remainingImportedFiles];
    } else {
      return remainingImportedFiles;
    }
  }

  async #createProcessApplication({ selectedProject, resourcesMetadata, folderId, isFolderProcessApplication }) {
    if (isFolderProcessApplication) {
      return {};
    }

    const firstBpmn = resourcesMetadata.find((resource) => {
      return resource.type === BPMN;
    });

    if (!firstBpmn) {
      return {};
    }

    try {
      const processApplicationName = this.extractFileName(firstBpmn.templates[0], firstBpmn);
      const bpmnWithNeededAttributes = {
        content: firstBpmn.templates[0],
        ...firstBpmn,
        ...getBpmnAttributes({ type: BPMN, content: firstBpmn.templates[0] })
      };

      const response = await processApplicationService.createProcessApplication({
        projectId: selectedProject.id,
        parentId: folderId,
        processApplicationName,
        mainProcessFile: bpmnWithNeededAttributes
      });

      return { processApplicationId: response.id, mainProcess: { id: response.mainProcessFileId, ...firstBpmn } };
    } catch (e) {
      console.warn('Creating process application failed. Files will import directly into project.');
    }

    return {};
  }

  extractIcon(template, resource) {
    const importService = this.#getImportServiceForType(resource.type);
    if (importService) {
      return importService.extractIcon(template, resource);
    } else {
      return <Warning />;
    }
  }

  extractFileName(template, resource) {
    const importService = this.#getImportServiceForType(resource.type);
    if (importService) {
      return importService.extractFileName(template, resource);
    } else {
      return '';
    }
  }

  executePostImportAction(history, selectedProject, importedFiles) {
    if (importedFiles?.length === 0) {
      return;
    }

    const firstBpmnFile = importedFiles?.find((file) => file.type === BPMN);
    if (firstBpmnFile) {
      return importProcessTemplateService.executePostImportAction(history, selectedProject, [firstBpmnFile]);
    }

    if (importedFiles.length === 1) {
      const firstFile = importedFiles[0];
      const importService = this.#getImportServiceForType(firstFile.type);

      if (importService) {
        return importService.executePostImportAction(history, selectedProject, importedFiles);
      }
    }

    return super.executePostImportAction(history, selectedProject, importedFiles);
  }

  #getImportServiceForType(type) {
    switch (type) {
      case DMN:
        return importDmnService;
      case FORM:
        return importFormService;
      case BPMN:
        return importProcessTemplateService;
    }
  }

  #guessImportServiceFromSourceUrl(url, templates) {
    const guessedFileExtension = url.split('.').pop();

    const fileType = getFileTypeByExtensionOrContent(guessedFileExtension, templates);

    const firstImportServiceGuess = this.#getImportServiceForType(fileType);

    if (firstImportServiceGuess) {
      return firstImportServiceGuess;
    }

    // Covering some cases that utils/file-io doesn't always cover

    if (templates instanceof Array) {
      return this.#getImportServiceForType(CONNECTOR_TEMPLATE);
    } else if (guessedFileExtension === 'xml') {
      return this.#getImportServiceForType(BPMN);
    }
  }
}

export default new ImportDelegatorService();
